export default function Modal({title, actions, active=false, sticky=false, cancellable=true, onClose, children}){
  if (!active) <div/>
  
  return (
    <div className="modal is-active">
      <div className="modal-background" {...(!sticky ? {onClick: onClose} : {})} />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          {cancellable && <button className="delete" aria-label="close" onClick={onClose}/>}
        </header>
        <section className="modal-card-body">
          { children }
        </section>
        <footer className="modal-card-foot is-justify-content-center">
          { actions }
        </footer>
      </div>
    </div>
  )
}