import api, { toParams } from "../utils/api"
import _ from 'underscore'
import { randomId } from "../utils/formUtils"

const PREFIX = 'plutusuit'

// Actions are API Requests initiated by the user to the remove server.
const action = async (token, fields, method, url, success, failure) => {
  api.request(token, method, url, fields, failure, success)
}

// Login user
export const auth = async (fields, success, failure) => {
  action(
    null, { data: toParams(fields) }, 'POST', 'login', (res) => {
      if (res.status === 200)
        success(res)
      else 
        console.log("Status code failed.")
    },
    (err) => failure(err) 
  )
}

// Sign up user
export const signup = async (fields, success, failure) => {
  action(
    null, { data: toParams(fields) }, 'POST', 'signup', (res) => {
      if (res.status === 200)
        success(res)
      else
        console.log("Status code failed.")
    },
    (err) => failure(err) 
  )
}

const kdat = (s, u) => [s, PREFIX, u].join('-')

export const fetchAll = async (userData, schemaNames=[], success, failure) => {
  const { uid, session } = userData

  if (schemaNames.length === 0) {
    console.log('nothing to load.')
    return
  }

  Promise.all(schemaNames.forEach((schemaName) => action(
    session, null,
    'GET', `/q/${kdat(schemaName, uid)}`,
    success, failure
  )))
  
}

// Get all /q/CATEGORY
export const fetch = async (userData, schemaName, success, failure) => {
  if (!userData) {
    failure("Session Expired")
    return
  }

  const uid = userData.uid
  const token = userData.session
  if (!uid || !token) {
    failure("Session Expired")
    return
  }

  action(
    token, null,
    'GET', `/q/${kdat(schemaName, uid)}`,
    success, failure
  )
}

// Get One /q/CATEGORY/ID
export const show = async (userData, schemaName, id, success, failure) => {
  const { uid } = userData
  const token = userData.session
  action(
    token, null,
    'GET', `/q/${kdat(schemaName, uid)}/${id}`,
    success, failure
  )
}

export const create = async (userData, schemaName, fields, success, failure) => {
  const { uid } = userData
  const token = userData.session
  const id = fields.id || `${schemaName[0]}-${randomId()}`
  action(
    token, {
    category: kdat(schemaName, uid),
    name: id,
    tags: [schemaName],
    value: toParams({
      id: id,
      ...fields,
    })
  },
    'POST', '/',
    success, failure
  )
}

export const update = async (userData, schemaName, fields, success, failure) => {
  const { uid } = userData
  const token = userData.session
  const remoteId = fields?.remote_id
  action(
    token, {
    category: kdat(schemaName, uid),
    name: fields?.id,
    file: null,
    tags: [schemaName],
    value: toParams(_.omit(fields, 'remote_id'))
  },
    'PUT', `/${remoteId}`,
    success, failure
  )
}

export const destroy = async (userData, schemaName, remoteId, success, failure) => {
  const { uid } = userData
  const token = userData.session
  const category = `${schemaName}-plutusuit-${uid}`
  action(
    token, '',
    'DELETE', `/${category}/${remoteId}`,
    success, failure
  )
}