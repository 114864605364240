import generateForm from "../utils/formUtils";
import Loading from "./shared/Loading";

export default function Form({schema, onChange, data, errors, children}) {
  const fields = schema ? generateForm(schema, onChange, data, errors) : <Loading/>
  return (
    <div className="box has-background-white-ter container">
      <div className='is-flex-direction-column'>
        { fields }
      </div>
      { children }
    </div>
  )
}