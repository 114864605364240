import React from 'react';
import FieldInput from "./FieldInput"
import Calendar from 'react-calendar';
import moment from 'moment';
import {dateFormat} from '../../utils/forecaster'
import 'react-calendar/dist/Calendar.css';

const INI_DATE = null

export default function DateField({ name, label, beAbove=false, showStatus=false, controlStyle='', value, onChange, ...rest }) {
  const [expanded, setExpanded] = React.useState(false)
  const [dateValue, setDate] = React.useState(INI_DATE);
  const toggleCal = () => setExpanded(!expanded)

  React.useEffect(() => {
    const date = moment(value)
    if (moment(dateValue).isSame(dateValue)) return
    setDate(date.isValid() ? date.toDate() : '')

  }, [value])

  const updateTextDate = (event) => {
    const date = moment(event.target.value)
    onChange(event.target.value) 
    setDate(date.isValid() ? date.toDate() : '')
  }

  const updateCalendarDate = (date) => {
    setDate(date)
    onChange(moment(date).format(dateFormat))
    setExpanded(false)
  }

  const clearDate = () => {
    setDate(undefined)
    onChange(undefined)
  }

  const isDateInitial = (date) => {
    return date == undefined
  }

  const isDateValid = (date) => {
    return moment(date).isValid()
  } 

  const classState = React.useMemo(
    () => !showStatus || isDateInitial(dateValue) ? 
      '' : isDateValid(value) ? 
        'has-text-success' : 'has-text-danger',
    [dateValue, value]
  )

  const calIcon = React.useMemo(
    () => 
      !showStatus || isDateInitial(dateValue) ? 
      'day' : isDateValid(value) ? 
        'check' : 'xmark'
    ,[dateValue, value]
  )

  return (
    <React.Fragment>
      {beAbove && expanded && <Calendar tileClassName='is-rounded' className='has-background-white-bis has-text-dark make-fit my-4 is-rounded' minDetail='year' showNeighboringMonth={false} onChange={updateCalendarDate} value={dateValue} />}
      <FieldInput fieldStyle='has-addons' readOnly onClick={toggleCal} value={value} label={label || name} 
        controlStyle={`${controlStyle} has-icons-right has-icons-left`} 
        onChange={updateTextDate}
        buttons={(
          <button className={`button is-pulled-right ${classState}`} onClick={clearDate}>
            <i className='fa fa-eraser has-text-danger'/>
          </button>
        )}
        {...rest} type="text">
        <span className={`icon is-small is-left ${classState}`} >
          <i className={`fa ${!expanded ? `fa-calendar-${calIcon}` : 'fa-angle-up'}`}/>
        </span>        
      </FieldInput>
      
      {!beAbove && expanded && <Calendar tileClassName='is-rounded' className='has-background-white-bis has-text-dark make-fit my-4 is-rounded' minDetail='year' showNeighboringMonth={false} onChange={updateCalendarDate} value={dateValue} />}
    </React.Fragment>
  )
}